import React, { useState, useEffect, useContext } from 'react';
import osstore from '../../assets/images/apple.svg';
import googlestore from '../../assets/images/playstor.svg';
import imgBg from '../../assets/images/banner.png';
// import Input from '../../Components/UI/Input';
import { Wrapper } from './style';
import { useNavigate } from 'react-router-dom';

import ResetPassword from './ResetPassword';
import OTPVerification from './OTPVerification';
import { userAuthRequest } from '../../utils/api';
import { isEmpty } from '../../utils/common';
import { AlertWrapper } from '../UI/styledConstants';
import Loader from '../UI/Loader/Loader';
import { GlobleContaxt } from '../../Context/GlobleContaxt';
import Input from '../UIbox/Input';
import RadioButtonConfirm from '../UIbox/RadioButtonConfirm';
// import { InputWrapper } from '../UI/Input/style';

const initialState = { password: '', rememberMe: '', roleCode: 'ROLE_RETAILER', username: '' };

export default function Login() {
	const navigate = useNavigate();

	const [formDetails, setFormDetails] = useState(initialState);
	const [loading, setLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [forgetPasswordClicked, setForgetPasswordClicked] = useState(false);
	const [wasOTPSent, setWasOTPSent] = useState(false);

	const [message, setMessage] = useState({});

	const [phoneNumberForPasswordReset, setPhoneNumberForPasswordReset] = useState('');

	const { setModalConfig, setAlert } = useContext(GlobleContaxt);
	const numberReg = /^[0-9]+$/;
	const changeHandler = (event) => {
		event.preventDefault();
		if (event.target.name === 'username') {
			if (!numberReg.test(event.target.value) && event.target.value) {
				return false;
			}
		}

		setFormDetails({
			...formDetails,
			[event.target.name]: event.target.value,
		});
	};

	const rememberMeHandler = (event) => {
		if (event.target.value === 'rememberMe') {
			setFormDetails({
				...formDetails,
				[event.target.name]: '',
			});
			setFormDetails({
				...formDetails,
				[event.target.name]: event.target.value,
			});
		} else {
		}
	};

	const validateInput = () => {
		if (formDetails.username === '' || formDetails.password === '') {
			setMessage({ type: 'error', msg: 'Username And Password are required!', place: 'local' });
			return false;
		}
		if (formDetails.username.length < 10) {
			setMessage({ type: 'error', msg: 'Please enter 10 digit mobile number', place: 'local' });
			return false;
		}
		if (formDetails.password.length < 6) {
			setMessage({ type: 'error', msg: 'Please enter valid password', place: 'local' });
			return false;
		}

		return true;
	};

	const removeData = () => {
		localStorage.removeItem('ID Token');
		localStorage.removeItem('UserRole');
	};

	const forgetPasswordHandler = () => {
		setForgetPasswordClicked(true);
		setFormDetails(initialState);
	};

	const onSubmitHandler = async (event) => {
		event.preventDefault();
		const isValid = validateInput();
		if (!isValid) {
			return;
		}

		setMessage({});
		setLoading(true);
		const response = await userAuthRequest(formDetails);
		setLoading(false);
		if (response?.id_token) {
			localStorage.setItem('ID Token', response?.id_token);
			localStorage.setItem('UserRole', response?.authorities);

			setModalConfig({ status: false, config: null });
			navigate('/dashboard', { replace: true });
		} else {
			removeData();

			if (response?.errorCodeList?.length > 0) {
				setMessage({ type: 'error', msg: response?.errorCodeList[0], place: 'local' });
			} else {
				setMessage({ type: 'error', msg: response?.message || 'Something went wrong Please try again', place: 'local' });
			}
		}
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	return (
		<Wrapper>
			<div className="login-wrapper">
				<div className="left">
					<h1>PAY BILLS QUICKLY AND EASILY.</h1>
					
					<div>
						<img src={imgBg} alt='img' />
					</div>
					
				</div>
				{!forgetPasswordClicked && !wasOTPSent && (
					<div className="right">
					  <div>
					  <h3>To Login into your TechFORA Web account</h3>
						{!isEmpty(message) && message.place === 'local' && (
							<AlertWrapper className={`${message.type} local`}>
								<span className="close" onClick={() => setMessage({})}>
									×
								</span>
								{message.msg}
							</AlertWrapper>
						)}

						<Input label="Mobile No." name="username" maxLength={10} inputMode="numeric" onChange={(e) => changeHandler(e)} value={formDetails.username} required={true} />
						<Input
							label="Password"
							name="password"
							type={showPassword ? 'text' : 'password'}
							maxLength={20}
							onChange={(e) => changeHandler(e)}
							value={formDetails.password}
							toggle={true}
							required={true}
						/>

						<div className="forgot-password-link">
							<RadioButtonConfirm label={<>Remember me</>} name="rememberMe" checked={formDetails.rememberMe === 'rememberMe'} value={'rememberMe'} onChange={rememberMeHandler} />

							
						</div>
						<button className="login-button button-outline" onClick={onSubmitHandler}>
							LOGIN
						</button>
						<button className='forgetBtn' onClick={forgetPasswordHandler}>Forgot Your Password?</button>
					  </div>
						{loading && <Loader />}
					</div>
				)}
				{forgetPasswordClicked && !wasOTPSent && (
					<ResetPassword
						setForgetPasswordClicked={setForgetPasswordClicked}
						onSuccessfullySent={(phoneNumber) => {
							setWasOTPSent(true);
							setPhoneNumberForPasswordReset(phoneNumber);
						}}
					/>
				)}
				{wasOTPSent && <OTPVerification phoneNumber={phoneNumberForPasswordReset} setWasOTPSent={setWasOTPSent} setForgetPasswordClicked={setForgetPasswordClicked} />}
			</div>
		</Wrapper>
	);
}
