import { createGlobalStyle } from 'styled-components';
import imgBackground from './assets/images/backgroundImg.svg';
const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 22px;
  }
  html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px; 
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
  body {
    font-family: 'Noto Sans', sans-serif;
    background-color: #fff;
  
    color: #141414;
    -webkit-font-smoothing: antialiased;
    .logo .logo1{
      display: none;
    }
    &.home{
      background-color: #fff;
      &:before {
top: -410px;
height: 804px;
}
      .heading h2{
        color: #fff;
        margin-top: 80px;
        margin-bottom: 200px;
      }
      /* .top-header .header-buttons > .name .profile .logoName{
        color: #fff;
      } */
      .logo .logo2{
      display: none;
    }
      .logo .logo1{
      display: block;
    }
   
    .top-header .header-buttons > .name .profile,
    .header-balance .header-buttons > .name .profile{
      color: #333;
    }
 
    @media only screen and (max-width: 767px)
{
  .top-header .header-link {
    /* background-color: #fff; */
  }
 .top-header .toggle-icon .spinner{
  background-color: #fff;
 }
 /*
 .top-header .header-link ul li a {
     color: #fff!important;
 } */
}

    }
    overflow-x: hidden;
    &:before {
      content: '';
      position: absolute;
      top: -70px;
      left: -74px;
      width: calc(100% + 144px);
      height: 880px;
      background-image: url(${imgBackground});

      background-size: cover;
      z-index: -1;
    }
    /* &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 470px;
      width: 100%;
      height: 470px;
      background-color: #DFEBFF;
      z-index: -1;
      
    } */
  }
  .text20{
    font-size: 20px;
    line-height: 30px;
  }
 /* body.home::before{
 height: 103px;
 background-color: #fff;
}  */
  button, input {
    outline: none;
  }
  .none{
    outline: none;
    border: none;
    background: none;
}
  
  .container {
    max-width: 1240px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    &.card2wrapper,&.billSection{
    &>p{
        font-size: 18px;
        line-height: 30px;
        margin-top: 11px;
        margin-inline: auto;
        max-width: 600px;
        text-align: center;
        margin-bottom: 70px;
    }

}
.billSection > p{
  max-width: 800px;
  margin-top: 40px;
}
  }
.center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-2{
  font-size: 40px;
    font-weight: 300;
    line-height: 50px;
    text-align: center;
    color: #001A16;
   
}
.heading-2 .strong{

   font-weight: 700;
  
}
  ul,li{
    list-style:none;
  }
  a{
        text-decoration: none;
  }
  img{
    vertical-align: top;
  }
  .button-solid {
    background-color: #D44100;
    border: 1px solid #D44100;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    padding: 0 40px;
    font-weight: 700;
    cursor: pointer;
    transition: all .3s;
    border-radius: 25px;
    &:hover{
      background-color: #9d3304;
    border: 1px solid #9d3304;
    }
  }
  .button-outline {
    background: #D44100;
     box-shadow: 0px 1px 2px #00000029;
     border-radius: 25px;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    color: #FFF;
    padding: 0 10px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    border: none;
    transition: all .3s;
    &:hover{
      background-color: #9d3304;
      color: #fff;
    }
  }

  body.home .homeHeader{

   
    .header-link ul li a, 
     .header-balance .header-link ul li a,
      .top-header .header-link ul li .nav-more,
      .top-header .header-link ul li .a,
      .header-balance .header-link ul li .nav-more {
      color: #fff;
    }
  }
  .pdl-120{
    padding-left: 110px;
  }
.pd-10{
  padding: 10px;
}
.pd-20{
  padding: 20px;
}
  .pd-30{
    padding: 30px;
  }
  .pd-sm-30{
    padding: 30px;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .flex {
    display: flex;
  }
  .column{
    flex-direction: column;
  }
  .items-center {
    align-items: center;
  }
  .items-start{
    align-items: start;
  }
  .self-center{
    align-self: center;
  }
  .self-end{
    align-self: flex-end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-end {
    justify-content: flex-end;
  }
.align-start{
  align-items: flex-start;
}
  .justify-center{
    justify-content: center;
  }
  .text-center{
    text-align: center;
  }
  .page-msg {
    margin-bottom: 20px;
  }
  .error {
    color: red;
    font-size: 12px;
  }
  .success {
    color: green;
    font-size: 12px;
  }
  .lineHeight{
    line-height: 1;
  }
  .fs20{
    font-size: 20px;
  }
  .fs16{
    font-size: 16px;
  }
  .fs15{
    font-size: 15px;
  }
  .fs12{
    font-size: 12px;
  }
  .fs30{
    font-size: 30px;
  }
  .fs40{
    font-size: 40px;
  }
  .fs45{
    font-size: 45px;
  }
  .mb30{
    margin-bottom: 30px;
  }
  .mt10{
    margin-top: 10px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt25{
    margin-top: 25px;
  }
  .mt30{
    margin-top: 30px;
  }
  .mt40{
    margin-top: 40px;
  }
  .mt50{
    margin-top: 50px;
  }
  .mt60{
    margin-top: 60px;
  }
  .mt100{
    margin-top: 100px;
  }
  .mt110{
    margin-top: 110px;
  }
  .mt70{
    margin-top: 70px;
  }
  .mb10{
    margin-bottom: 10px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mr20 {
    margin-right: 20px;
  }
  .ml-auto{
    margin-left: auto;
  }
  .ml20 {
    margin-left: 20px;
  }
  .bold {
    font-weight: 600;
  }
  .medium {
    font-weight: 500;
  }
  .italic {
    font-style: italic;
  }
  .gap-6{
    gap: 6px;
  }
  .gap-10{
    gap: 10px;
  }
  .gap-20{
    gap: 20px;
  }
  .gap-50{
    gap: 50px;
  }
  .gap-40{
    gap: 40px;
  }
  .gap-30{
    gap: 30px;
  }
  .gap-100{
    gap: 100px;
  }
  .gap-5{
    gap: 5px;
  }

  .w-35{
    width: 35%;
  }
  .w-50{
    width: 50%;
  }

  .w-100{
    width: 100%;
  }
  .row {
    display: flex;
    margin: 0 -12px;
    [class*="col-"] {
      padding: 0 12px;
    }
  }
  .col-1 {
    flex: 0 0 calc(100% * 1 / 12);
  }
  .col-2 {
    flex: 0 0 calc(100% * 2 / 12);
  }
  .col-3 {
    flex: 0 0 calc(100% * 3 / 12);
  }
  .col-4 {
    flex: 0 0 calc(100% * 4 / 12);
  }
  .col-5 {
    flex: 0 0 calc(100% * 5 / 12);
  }
  .col-6 {
    flex: 0 0 calc(100% * 6 / 12);
  }
  .col-7 {
    flex: 0 0 calc(100% * 7 / 12);
  }
  .col-8 {
    flex: 0 0 calc(100% * 8 / 12);
  }
  .col-9 {
    flex: 0 0 calc(100% * 9 / 12);
  }
  .col-10 {
    flex: 0 0 calc(100% * 10 / 12);
  }
  .col-11 {
    flex: 0 0 calc(100% * 11 / 12);
  }
  .col-12 {
    flex: 0 0 calc(100% * 12 / 12);
  }
  .heading h2{
    text-align: center;
    color: #fff;
    margin-bottom: 40px;
  }
  .heading label{
    text-align: center;
    margin-bottom: 40px;
  }
  .d-m-block{
    display: none;
  }
  
  .d-m-fade{
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
  }
.block{
  display: block;
}
.cursor{
  cursor: pointer;
}
  .csv-link{
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 13px;
    border: none;
    line-height: 20px;
    background-color: rgb(0, 186, 242);
    padding: 10px 20px;
    cursor: pointer;
    margin-left: 10px;
    .btn-loader{
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
  }
  
  .grow-1{
    flex-grow: 1;
  }

  .tooltip {
  position: relative;
  display: inline-block; 
  &::after{
    content: attr(tooltip-data);
    display: block;
    visibility: hidden;
    opacity: 0;
  min-width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;

  position: absolute;
  z-index: 1;
  transition: all .3s;
  }
  &:hover{
    &::after{
      opacity: 1;
      visibility: visible;
    }
  }
}

/* Tooltip text */


  @media only screen and (max-width: 767px){
    .d-m-block{
    display: block;
  }
  .d-m-fade{
    visibility: visible;
    opacity: 1;
   
  }
  
  .d-m-none{
    display: none !important;
  }
  .personal-information{
    width: 100%;
  }
  
  }
  @media print {
		body{
        visibility: hidden;
		
    }
   
  }
  @media only screen and (max-width: 767px){
    .heading-2{
      font-size: 26px;
      line-height: 34px!important;
    }
  }
  
  @media only screen and (max-width: 480px){
    .fs-sm-12{
      font-size: 12px!important;
    }
    .fs-sm-20{
      font-size: 20px!important;
      line-height: 22px!important;
    }
    .pd-sm-30{
    padding: 0px;
  }
    .pd-sm-30{
    padding: 0px;
  }
  .d-sm-none{
    display: none!important;
  }
  .p-sm-0{
    padding: 0!important;
  }
  .m-sm-0{
  margin: 0!important;
  }
  .pt-sm-40{
    padding-top: 40px;
  }
  }
`;

export default GlobalStyle;
