import React, { useEffect, useState } from 'react';
import Input from '../../Components/UI/Input';
import Loader from '../UI/Loader/Loader';
import { isEmpty } from '../../utils/common';
import { AlertWrapper } from '../UI/styledConstants';
import { handleResetNumberOtp } from '../../utils/api';

const ResetPassword = ({ onSuccessfullySent, setForgetPasswordClicked }) => {
	const [mobileNumber, setMobileNumber] = useState('');
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState({});

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const handleResetNumber = () => {
		if (isEmpty(mobileNumber)) {
			setMessage({ type: 'error', msg: 'Please enter Mobile Number', place: 'local' });
			return false;
		} else if (mobileNumber.length < 10) {
			setMessage({ type: 'error', msg: 'Mobile number must be 10 digit', place: 'local' });
		} else {
			setLoading(true);
			const param = {
				mobileNumber: mobileNumber,
				roleCode: 'ROLE_RETAILER',
			};

			handleResetNumberOtp(param).then((resp) => {
				setLoading(false);
				if (resp.success) {
					onSuccessfullySent(mobileNumber);
				} else {
					if (resp?.errorCodeList.length > 0) {
						setMessage({ type: 'error', msg: resp?.errorCodeList, place: 'local' });
					} else {
						setMessage({ type: 'error', msg: resp?.message, place: 'local' });
					}
				}
			});
		}
	};

	const numberReg = /^[0-9]+$/;
	const changeHandler = (event) => {
		if (!numberReg.test(event.target.value) && event.target.value) {
			return false;
		}
		setMobileNumber(event.target.value);
	};

	return (
		<div>
			<div className="right">
				<h3>Change Password</h3>
				{!isEmpty(message) && message.place == 'local' && (
					<AlertWrapper className={`${message.type} local`}>
						<span className="close" onClick={() => setMessage({})}>
							×
						</span>
						{message.msg}
					</AlertWrapper>
				)}

				<Input
					elemConfig={{
						type: 'text',
						name: 'mobileNumber',
						value: mobileNumber,
						maxLength: '10',
						inputMode:"numeric"
					}}
					placeholder={'Mobile No.'}
					onChange={changeHandler}
				/>

				<div className="forgot-password-link" style={{ margin: '6px 0' }}>
					<button
						onClick={() => {
							setForgetPasswordClicked(false);
						}}
					>
						Back To Login
					</button>
				</div>

				<button className="login-button" onClick={handleResetNumber}>
					Send OTP
				</button>
			</div>
			{loading && <Loader />}
		</div>
	);
};

export default ResetPassword;
