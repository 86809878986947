/* eslint-disable import/no-anonymous-default-export */

const financeService ="/finance-service"
const  auth = "/auth"
export default {
	//API_BASEPATH: 'http://165.22.208.28:8080',
	API_BASEPATH: 'https://pay2mobiles.com',
	login: {
		API_AUTHENTICATE: auth+'/api/authenticate',
		API_PERMISSION: auth+'/api/frontend/permissions',
		RESET_PASSWORD: auth+'/api/user/account/reset-password/init',
		SEND_OTP: auth+'/api/service/send-otp',
		VERIFY_OTP: auth+'/api/user/account/reset-password/finish',
		USER_PROFILE: financeService+'/api/user/account',
		// CHANGE_PASSWORD: '/api/user/account/change-password',
	},
	mobileRecharge: {
		RECHARGE: financeService+'/api/recharge/phoneNumber',
		PLANS: financeService+'/api/recharge/plan',
		ZONE: financeService+'/api/zone/list',
		OPERATOR: financeService+'/api/operator/MOBILE',
	},
	transactionHistory: {
		//TRANSACTION_HISTORY: financeService+'/api/report/transactions',
		TRANSACTION_HISTORY: financeService+'/api/report/admin/transactions',
	},
	dth: {
		DTH_RECHARGE: financeService+'/api/recharge/DTH',
		DTH_PLANS: financeService+'/api/recharge/DTH/plan',
		DTH_DETAILS: financeService+'/api/recharge/DTH/',
		OPERATOR: financeService+'/api/operator/DTH',
	},
	payBills: {
		GET_PROVIDER_LIST_URL: financeService+'/api/bill/provider/list',
		GET_PROVIDER_DETAILS_URL: financeService+'/api/bill/provider/details',
		FETCH_BILL_URL: financeService+'/api/bill/enquiry',
		PAY_BILL_URL: financeService+'/api/bill/pay',
	},
	water: {
		OPERATOR: financeService+'/api/bill/WATER',
	},
	broadband: {
		OPERATOR: financeService+'/api/bill/BROADBAND',
	},
	wallet: {
		USER_WALLET: financeService+'/api/wallet',
		//USER_WALLET_TRANSACTIONS: financeService+'/api/wallet/transactions',
		USER_WALLET_TRANSACTIONS: financeService+'/api/wallet/filter/transactions',
	},
	sendMoney: {
		VERIFY_SENDER_STATUS_URL: financeService+'/api/sendMoney/senderStatus',
		REGISTER_SENDER_URL: financeService+'/api/sendMoney/registerSender',
		SEND_MONEY_URL: financeService+'/api/sendMoney',
		ADD_BENEFICIARY_URL: financeService+'/api/sendMoney/registerBeneficiary',
		VERIFY_SENDER_URL: financeService+'/api/sendMoney/verifySender',
		DELETE_BENEFICIARY_URL: financeService+'/api/sendMoney/deleteBeneficiary',
		DMT_BANK_LIST: financeService+'/api/sendMoney/bank/list',
		PENNY_DROP: financeService+'/api/penny-drop',
	},
	aeps: {
		AEPS_BANK_LIST: financeService+'/api/aeps/bank/list',
		// CASH_WITHDRAWAL: financeService+'/api/aeps/cash-withdrawal',
		// CASH_WITHDRAWAL: financeService+'/api/aeps/cash-withdrawal',
		// BALANCE_ENQUIRY: financeService+'/api/aeps/balance-enquiry'
		
		CHECK_AEPS_STATUS:financeService+'/api/aeps/outlet/login/status',
		MERCHANT_LOGIN_FINGERPRINT:financeService+'/api/aeps/outlet/login',
	},
	aepsDiosm:{
		DIOSM_MERCHAT_OTP:financeService+'/api/aeps/v2/merchant/otp',
	    DIOSM_MERCHAT_OTP_VERIFY:financeService+'/api/aeps/v2/otp/verify',
	    DIOSM_MERCHAT_DETAIL:financeService+'/api/aeps/v2/get/merchant/detail',
	    DIOSM_AEPS_EKYC_BIOMETRIC:financeService+'/api/aeps/v2/ekyc/biometric',
	    DIOSM_AEPS_DAILY_LOGIN:financeService+'/api/aeps/v2/daily/login',
		DIOSM_CASH_WITHDRAWAL: financeService+'/api/aeps/v2/transaction/cash-withdrawal',
		DIOSM_BALANCE_ENQUIRY: financeService+'/api/aeps/v2/transaction/balance-enquiry',
		DIOSM_MINI_STATEMENT: financeService+'/api/aeps/v2/transaction/mini-statement',
		DIOSM_AEPS_LOGIN_STATUS:financeService+'/api/aeps/v2/merchant/daily/login/status',
	},
	aadharPay:{
		AADHAR_PAY_WITHDRAWAL:financeService+'/api/aeps/v2/transaction/aadhaar-pay'
	},
	creditCard: {
		CREDIT_CARD_BILL_PAY: financeService+'/api/bill/credit-card',
	},
	addmoney: {
		GENERATE_QR: financeService+'/api/pg/create-qr-order',
		QR_CHECK_STATUS: financeService+'/api/pg/payment-summary/',
		GENERATE_PG_LINK: financeService+'/add-balance/',
	},
	dqr:{
		CREATE_DQR:financeService+"/dqr/create-dqr",
		CANCEL_DQR:financeService+"/dqr/cancel-dqr",
		CHECK_STATUS_DQR:financeService+"/dqr/checkStatus-dqr",
	}
};
