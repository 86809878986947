

import React, { useRef, useState } from 'react'
import { InputWrapper } from './style'
import { isEmpty } from '../../utils/common';



function Input({children,type="text", value="", name="", label="",error="", onChange=() =>{},onClick=()=>{},className="",toggle=false,potions=[],keys=null,title="",...others}) {
    const [focus,setFocus] = useState("");
    const [passwordState, setPasswordState] = useState(true);
    const input = useRef();

    const focusInput = () =>{
    input.current.focus();
    onClick()
    }
  return (
    <InputWrapper className={className}>
    <div className={`inputField ${focus}`} onClick={()=>focusInput}
    title={title}
      >
    <label htmlFor={name}>{label}</label>
  
  {(type !== "select") &&<>  <input ref={input}
    id={name}
     type={type === "password"?`${passwordState?"password":"text"}`:type} value={value} name={name} onChange={onChange} {...others}  onFocus={()=>setFocus("focus")} onBlur={()=>setFocus("")} />
   { (toggle && type === "password") && <div className="toggel" onClick={() =>{setPasswordState(!passwordState)}}>{passwordState ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}</div>}
    </>}

    {type === "select" && 
    <select value={value} name={name} onChange={onChange} {...others}  onFocus={()=>setFocus("focus")} onBlur={()=>setFocus("")}>
 
    {!isEmpty(potions) && potions.map((value, i) => {
        return (
            <option key={i} value={value[keys.value]}>
                {value[keys.label]}
            </option>
        );
    })}
</select>}
{children}
    </div>

    {(error !=="")&&<div className="error">
       {error}

    </div>}
   
 
   
    
    </InputWrapper>
  )
}

export default Input;