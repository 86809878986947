import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	box-shadow: 0px 3px 50px #00000029;
	border-radius: 20px;
	background-color: #fff;
	.forgetBtn{
		background-color: transparent;
		border: none;
		outline: none;
		color: #071926;
		margin-top: 16px;
		text-decoration: underline;
		font-size: 13px;
		cursor: pointer;
	}
	.i-icon {
		display: block;
		position: absolute;
		right: 9px;
		font-size: 16px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		cursor: pointer;
	}
	.login-wrapper {
		display: flex;
		& > div {
			width: 50%;
		}
	}
	.left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		padding: 36px 26px 0;
		
		&>div{
			width: 400px;
		}
		h1 {
			font-size: 30px;
			line-height: 39px;
			
			line-height: 36px;
			color: #000;
			font-style: italic;
			position: relative;
		}
		h2 {
			font: normal normal medium 25px;
			margin-top: 18px;
		}
		p {
			font: normal normal medium 13px/20px;
			margin-bottom: 23px;
		}
		img{
			position: relative;
			right: 120px;
		}
		.download-app {
			margin-top: 22px;
		}
	}
	.right {
		flex-shrink: 0;
		padding: 50px 26px 36px;
		position: relative;
		&>div{
			width: 282px;

			margin-inline: auto;
		}
		h3 {
			font: normal normal bold 20px/30px;
			margin-bottom: 37px;
		}
		input {
			font-size: 16px;
		}
		.input-container + .input-container {
			margin-top: 26px;
		}
		.forgot-password-link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;
			margin-bottom: 5px;
			font-size: 12px;
			white-space: nowrap;
			button {
				border: none;
				background-color: transparent;
				font: normal normal medium 12px;
				color: #00baf2;
				cursor: pointer;
			}
		}
		.login-button,
		.register-button {
			width: 100%;
			height: 50px;
			margin-top: 20px;
		}     

		.login-button {
	
			transition: all .3s;
			
		}
		.register-button {
			border: 1px solid #333;
			background-color: #fff;
			color: #333;
		}
	}
	.app-store {
		gap: 10px;

		img {
			display: block;
			width: 120%;
		}
	}
	@media only screen and (max-width: 767px) {
		max-width: 400px;
		min-height: calc(280px);
		margin: auto;
		.login-wrapper {
			max-width: 400px;
			& > div {
				width: 100%;
				margin: auto;
			}
		}
		.left {
			display: none;
		}
		.right .forgot-password-link {
			display: block;
			&button {
				display: block;
			}
		}
	}
`;
