import React from 'react';

function PageLoader() {
	return (
		<div className="pageLoaderWrapper center">
			  <div class="pageloader"></div> 
		</div>
	);
}

export default PageLoader;
