import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Login from './Components/Login';
import Modal from './Components/Modal';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollTop from './Components/ScrollTop/ScrollTop';

import { GlobleContaxt } from './Context/GlobleContaxt';
import ProtectedRoute from './Pages/ProtectedRoute';


import { isEmpty } from 'lodash';
import { getLocation } from './utils/common';
import NotificationWrapper from './Components/Notification/NotificationWrapper';
import PageLoader from './Components/PageLoader';

const MainPage = lazy(() => import('./Pages/MainPage/MainPage'));
const Homepage = lazy(() => import('./Pages/Homepage'));
 const TransactionHistory = lazy(() => import('./Components/Header/TransactionHistory'));
const WalletHistory = lazy(() => import('./Components/Header/WalletHistory'));
const UserProfile = lazy(() => import('./Components/Login/UserProfile/UserProfile'));
const Business = lazy(() => import('./Pages/Business/Business'));
const Consumer = lazy(() => import('./Pages/Consumer/Consumer'));
const TermsandConditions = lazy(() => import('./Pages/TermsandConditions/TermsandConditions'));
const PrivacyPolicy = lazy(() => import('./Pages/PrivacyPolicy/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('./Pages/RefundPolicy/RefundPolicy'));
const Company = lazy(() => import('./Pages/Company/Company'));
const PackageDetail = lazy(() => import('./Pages/PackageDetails/PackageDetail'));
const Contactus = lazy(() => import('./Pages/ContactUs/Contactus'));
const Faq = lazy(() => import('./Pages/FAQ/Faq'));
const Services = lazy(() => import('./Pages/Services/Services'));
const BbpsComplaint = lazy(() => import('./Pages/BBPSComplaint/BbpsComplaint'));


function App() {
	const [modalConfig, setModalConfig] = useState({ status: false, config: null });
	const [userDetails, setUserDetails] = useState({});
	const [apiPermission, setApiPermission] = useState([]);
	const [alert, setAlert] = useState({});
	const [headerType, setHeaderType] = useState('');
	const [refresh, setRefresh] = useState(false);
	const [geolocation, setGeolocation] = useState({});

	const location = useLocation();
	const handleModal = (status = false) => {
		setModalConfig({ ...modalConfig, status });
	};


	useEffect(() => {
		const pathArr = ["/user-profile",'/dashboard',"","/"]
		const body = document.getElementById('body');
		if (pathArr.includes(location.pathname)) {
			setHeaderType('homeHeader');
			body.classList.remove('home');
		} else {
		
				body.classList.add('home');
			
		}
	}, [location.pathname, userDetails]);

	useEffect(() => {
		if (!isEmpty(localStorage.getItem('ID Token'))) {
			getLocation().then((position) => {
				setGeolocation(position);
			});
		}
	}, [localStorage.getItem('ID Token')]);

	const globleState = { userDetails, setUserDetails, setModalConfig, setApiPermission, apiPermission, setRefresh, refresh, geolocation, setGeolocation, setAlert, alert };

	return (
		<GlobleContaxt.Provider value={{ ...globleState }}>
			<Header handleModal={handleModal} cls={headerType} />
			<ScrollTop />
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<PageLoader />}>
							<MainPage />
						</Suspense>
					}
				/>
				<Route
					path="/dashboard"
					element={
						<Suspense fallback={<PageLoader />}>
							<Homepage />
						</Suspense>
					}
				/>
			 <Route
					path="/transaction-history" 
					element={
						<Suspense fallback={<PageLoader />}>
							<ProtectedRoute Component={TransactionHistory} />
						</Suspense>
					}
				/>
				
			
				<Route
				path="/wallet-history"
					element={
						<Suspense fallback={<PageLoader />}>
					     	<ProtectedRoute Component={WalletHistory} />
						</Suspense>
					}
				/>
				<Route
				path="/user-profile"
					element={
						<Suspense fallback={<PageLoader />}>
							<ProtectedRoute Component={UserProfile} />
						</Suspense>
					}
				/>
				
				<Route
				path="/business"
					element={
						<Suspense fallback={<PageLoader />}>
							<Business />
						</Suspense>
					}
				/>
				<Route
				path="/consumer"
					element={
						<Suspense fallback={<PageLoader />}>
							<Consumer />
						</Suspense>
					}
				/>
				<Route
				path="/terms-conditions"
					element={
						<Suspense fallback={<PageLoader />}>
							<TermsandConditions />
						</Suspense>
					}
				/>
				<Route
				path="/privacy-policy"
					element={
						<Suspense fallback={<PageLoader />}>
							<PrivacyPolicy />
						</Suspense>
					}
				/>
				<Route
				path="/refundpolicy"
					element={
						<Suspense fallback={<PageLoader />}>
							<RefundPolicy />
						</Suspense>
					}
				/>
				<Route
				path="/company"
					element={
						<Suspense fallback={<PageLoader />}>
							<Company />
						</Suspense>
					}
				/>
				<Route
				path="/packages"
					element={
						<Suspense fallback={<PageLoader />}>
							<PackageDetail />
						</Suspense>
					}
				/>
				<Route
				path="/contactus"
					element={
						<Suspense fallback={<PageLoader />}>
							<Contactus />
						</Suspense>
					}
				/>
				<Route
				path="/faq"
					element={
						<Suspense fallback={<PageLoader />}>
							<Faq />
						</Suspense>
					}
				/>
				<Route
				path="/our-services"
					element={
						<Suspense fallback={<PageLoader />}>
							<Services />
						</Suspense>
					}
				/>
				<Route
				path="/bbpscomplaint"
					element={
						<Suspense fallback={<PageLoader />}>
							<BbpsComplaint />
						</Suspense>
					}
				/>

			</Routes>

			<Footer />
			<Modal width="806px" handleModal={handleModal} status={modalConfig.status}>
				<Login />
			</Modal>

			<NotificationWrapper />
		</GlobleContaxt.Provider>
	);
}

export default App;
