import styled from "styled-components";



export const RadioButtonConfirmWrapper = styled.div`

.container {
  display: block;
  position: relative;
  padding-left: 22px;

  cursor: pointer;
  font-size: 10px;
  user-select: none;
  p{
    font-size: 13px;

    line-height: 22px;
    margin-bottom: 0!important;
  }
  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
}




.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;
}


.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #FA514C;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}


.container .checkmark:after {
  top: 5.5px;
  left: 5.5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}


  .error{
    color: #FF0000;
    background-color: #FFE9E9;
    padding: 4px 10px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 600;

    width: 100%;
  }

`;


export const InputWrapper = styled.div`
margin-bottom: 20px;
width: 100%;
flex: 1 1 50%;
margin-top: 10px;
/* font-family: "Lato", sans-serif; */
  .inputField{
   
position: relative;

.icon{
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

label{
    text-align: left;
    font-size: 12px;
    font-weight: 600;
letter-spacing: 0px;
color: #000000;
    display: block;
    text-align: start;
    position: absolute;
    top: 14px;
    transform: translateY(-50%);
    left: 20px;
    z-index: 1;
}
.react-datepicker-wrapper{
  width: 100%;
}
input,
select{
  
    font-size: 13px;
    font-weight: 500;
    height: 50px !important;
    border: none;
    width: 100%;
    outline: 1px solid transparent;
    background: #F6F6F6 0% 0% no-repeat padding-box;
    border-radius: 8px  !important;
    padding: 29px 20px 6px!important;
    &:focus{
        border: none;
        outline: 1px solid #071926;
        background-color: none;
    }
    &:focus-visible{
        border: none;
    }
}
&.focus{
    /* border: 1px solid #1A4F43; */
}
.toggel{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
  }
  .error{
    color: #FF0000;
    background-color: #FFE9E9;
    padding: 4px 10px;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 600;

    width: 100%;
  }
  .inputButton{
    position: absolute;
    right: 8px;
    top: 15px;
    background: #071926 0% 0% no-repeat padding-box;
border-radius: 23px;
padding: 0 9px;
color: #fff;
font-size: 10px;
border: none;
cursor: pointer;
  }
`
;