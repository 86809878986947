import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ Component }) => {
	const navigate = useNavigate();
	let token = localStorage.getItem('ID Token');
	useEffect(() => {


		if (isEmpty(token)) {
			navigate('/');
		}
	});

	if(isEmpty(token)){
		return <div style={{minHeight:"80vh"}}>Loding....</div>;
	}else{
		return <Component />;
	}
	
};

export default ProtectedRoute;
