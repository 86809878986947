import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-2.svg';
import logo1 from '../../assets/images/logoBlack.svg';
import jwt from 'jsonwebtoken';
import userIcon from "../../assets/images/userIcon.webp"

import { Wrapper } from './style';
import { useState, useEffect, useContext } from 'react';

import { isEmpty } from 'lodash';
import { AlertWrapper } from '../UI/styledConstants';
import { GlobleContaxt } from '../../Context/GlobleContaxt';
import { getApiPermission, getUserProfileRequest, getUserWalletRequest } from '../../utils/api';
import caretDown from '../../assets/images/caret-down.svg';
import aeps from '../../assets/images/aeps-balance.svg';
import main from '../../assets/images/main-balance.svg';
import TravelAutoLogin from '../../TravelAutoLogin';

function Component({ handleModal = () => {}, cls }) {
	const [message, setMessage] = useState({});
	const [toggle, setToggle] = useState(false);
	const [balance, setBalance] = useState({});

	const navigate = useNavigate();

	const { pathname } = useLocation();
	useEffect(() => {
		setToggle(false);
	}, [pathname]);

	const { userDetails, setUserDetails, setApiPermission, refresh, setAlert } = useContext(GlobleContaxt);

	useEffect(() => {
		if (localStorage.getItem('ID Token')) {
			const user_role = localStorage.getItem('UserRole');
			getUserProfileRequest().then((resp) => {
				if (resp?.success) {
					setUserDetails(resp?.data);
				} else {
				}
			});

			getApiPermission(user_role).then((resp) => {
				if (resp?.success) {
					if (isEmpty(resp?.data?.permissionList)) {
						setApiPermission([]);
					} else {
						setApiPermission(resp?.data?.permissionList);
					}
				}
			});
		}
	}, [localStorage.getItem('ID Token')]);

	const getWalletBalance = () => {
		getUserWalletRequest('').then((resp) => {
			if (resp?.success) {
				setBalance(resp?.data);
			}
		});
	};

	useEffect(() => {
		getWalletBalance();
	}, [localStorage.getItem('ID Token'), refresh]);

	useEffect(() => {
		if (localStorage.getItem('ID Token')) {
			const token = localStorage.getItem('ID Token');
			const decoded = jwt.decode(token);
			const Intervalid = setInterval(() => {
				try {
					if (decoded) {
						const expirationTimestamp = decoded.exp;

						const expirationDate = new Date(expirationTimestamp * 1000);
						if (expirationDate <= Date.now()) {
							logOut('exp');
							clearInterval(Intervalid);
						}
					} else {
						console.error('Token is not valid');
					}
				} catch (error) {
					console.error('An error occurred:', error.message);
				}
			}, 6000);
		}
	}, [localStorage.getItem('ID Token')]);

	const logOut = (key) => {
		sendMessageToIframe('logOut');
		localStorage.removeItem('ID Token');
		localStorage.removeItem('UserRole');
		setUserDetails({});
		if (key === 'logout') {
			setAlert({ type: 'success', msg: 'User logout successfully' });
		}
		if (key === 'exp') {
			setAlert({ type: 'error', msg: 'Your Session Expired Please Login Again To Continue' });
		}

		setUserDetails({});
		navigate('/', { replace: true });
	};

	useEffect(() => {
		if (!isEmpty(message)) {
			setTimeout(() => {
				setMessage({});
			}, 5000);
		}
	}, [message]);

	const spinner = () => {
		setToggle(!toggle);
	};

	const sendMessageToIframe = (key = 'login') => {
		const iframe = document.getElementById('myIframe');

		if (key === 'login') {
			///iframe.contentWindow.postMessage({token:localStorage.getItem('ID Token')}, 'https://travel.TechFora.com');

			var link = document.createElement('a');
			link.href = 'https://travel.TechFora.com';
			link.target = '_blank';
			link.click();
		} else {
			iframe.contentWindow.postMessage({ logout: true }, 'https://travel.TechFora.com');
		}
	};

	const handleClick = () => {
		let token = localStorage.getItem('ID Token');

		if ((typeof token === 'string' && token === 'null') || isEmpty(token)) {
			logOut('');
			document.removeEventListener('click', handleClick);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [localStorage.getItem('ID Token')]);

	return (
		<Wrapper className={`container ${cls}`}>
			{Object.keys(userDetails) == 0 ? (
				''
			) : (
				<div className="header-balance flex justify-between">
					{Object.keys(userDetails) == 0 ? (
						<div></div>
					) : (
						<div className="balance flex">
							{userDetails?.userName !== '8527141474' && (
								<div className="aeps-balance flex">
									<div className="img">
										<img src={aeps} alt="aeps-img" />
									</div>
									<div className="desc">
										<p className="heading">AEPS Balance </p>
										<p className="value">₹ {balance?.AEPS_WALLET?.toFixed(2)}</p>
									</div>
								</div>
							)}
							<div className="main-balance flex">
								<div className="img">
									<img src={main} alt="main-wallet-img" />
								</div>
								<div className="desc">
									<p className="heading">Main Balance </p>
									<p className="value">₹ {balance?.MAIN_WALLET?.toFixed(2)}</p>
								</div>
							</div>
						</div>
					)}
					{Object.keys(userDetails) == 0 ? (
						<div className="header-buttons">
							<button className="login button-outline" onClick={() => handleModal(true)}>
								Login
							</button>
						</div>
					) : (
						<div className="header-buttons">
							<div className="name ">
							
								<div className="profile">
									<div className="right flex">
										<div className="icon">
											<img src={userIcon} alt="" />
										</div>
										<div className="name center" style={{gap:"6px"}} ><span> Hi,{userDetails.firstName || ''}</span><img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} /></div>
									</div>
								
									<div className="profile-dropdown">
										<ul>
											<li>
												<span onClick={() => logOut('logout')}>Logout</span>
											</li>
											<li>
												<Link to="/user-profile">User Profile</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="top-header">
				<div className="logo">
					<Link to="/">
						<img className="logo" src={logo}></img>
					</Link>
				</div>

				<div className={`header-link ${toggle ? 'active' : ''}`}>
					<ul>
						<li>
							<NavLink to="/">Home</NavLink>
						</li>

						<li>
							<NavLink to="/dashboard">Services</NavLink>
						</li>
						
						<li className="d-m-block">
							<NavLink to="/business">Business</NavLink>
						</li>
						<li>
							<NavLink to="/consumer">Consumer</NavLink>
						</li>

						<li>
							<NavLink to="/company">Company</NavLink>
						</li>
						<li >
							<NavLink to="/packages">Plans</NavLink>
						</li>
						<li className="d-m-block">
							<NavLink to="/refundpolicy">Refund Policy</NavLink>
						</li>

						{/* <li className="dropdown_li">
							<div className="nav-more center" style={{ gap: '10px' }}>
								More <img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} />
								<div className="profile-dropdown">
									<ul>
										<li>
											<NavLink to="/business">Business</NavLink>
										</li>
										<li>
											<NavLink to="/packages">Plans</NavLink>
										</li>
										<li>
											<NavLink to="/refundpolicy">Refund Policy</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</li> */}

						{userDetails?.firstName && (
							<>
								<li className="d-m-block">
									<NavLink to="/user-profile">User Profile</NavLink>
								</li>
							</>
						)}
					</ul>
				</div>
				<div className="header-buttons">
					{Object.keys(userDetails) == 0 ? (
						<>
							<button className="login button-outline" onClick={() => handleModal(true)}>
								Login
							</button>
						</>
					) : (
						<>
							<div className="balance flex d-s-none">
								{userDetails?.userName !== '8527141474' && (
									<div className="main-balance flex">
										<div className="img">
											<img src={aeps} alt="aeps-img" />
										</div>
										<div className="desc">
											<p className="heading">AEPS Balance </p>
											<p className="value">₹ {balance?.AEPS_WALLET?.toFixed(2)}</p>
										</div>
									</div>
								)}
								<div className="main-balance flex">
									<div className="img">
										<img src={main} alt="main-wallet-img" />
									</div>
									<div className="desc">
										<p className="heading">Main Balance </p>
										<p className="value">₹ {balance?.MAIN_WALLET?.toFixed(2)}</p>
									</div>
								</div>
							</div>
							<div className="name d-s-none">
								<div className="profile">
									<div className="right flex">
										<div className="icon">
											<img src={userIcon} alt="" />
										</div>
										<div className="name center" style={{gap:"6px"}} ><span> Hi,{userDetails.firstName || ''}</span><img src={caretDown} alt="caretDown" style={{ display: 'inline-block' }} /></div>
									</div>

									<div className="profile-dropdown">
										<ul>
											<li>
												<span onClick={() => logOut('logout')}>Logout</span>
											</li>
											<li>
												<Link to="/user-profile">User Profile</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</>
					)}
					<div className={`toggle-icon ${toggle ? 'active' : ''}`} onClick={spinner}>
						<div className="spinner"></div>
						<div className="spinner"></div>
					</div>
				</div>
			</div>
			<TravelAutoLogin logOut={logOut} />
		</Wrapper>
	);
}

export default Component;
