import styled from 'styled-components';


export const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(200, 200, 200, 0.8);
	opacity: 1;
	z-index: 10000;
	backdrop-filter: blur(12px);
	-webkit-backdrop-filter: blur(12px);
	.modal {
		position: absolute;
		width: 100%;
		max-width: ${({ width }) => (width ? `${width}` : '806px')};
		min-width: 280px;
		height: auto;
		top: 50px;
		left: 50%;
		transform: translate(-50%, 0);
		background: #fff;
box-shadow: 0px 3px 50px #00000029;
border-radius: 20px;
		z-index: 2;
	
	}
	.modal-close {
		width: 20px;
		height: 20px;
		display: flex;
		position: absolute;
	
		right: 20px;
		top: 20px;
		color: #333;
		border: none;
		background-color: transparent;
		cursor: pointer;
	
		z-index: 1;
		svg{
				transition: all .3s;
			}
		
			&:hover{
				svg{
					fill: red;
					transform: rotate( 180deg);
				}
			}
	}
`;
