import styled from 'styled-components';

export const TableWrapper = styled.div`
	overflow: auto;

	font-size: 14px;
	position: relative;
	min-height: 80px;
	border-radius: 8px;
	.BilDaitalHeader{
		
		align-items: center;
		margin-bottom: 20px;
		img{
			height:33px;
		}
		h2{
			color:#000;
			height:auto;
			line-height:auto;
			margin-block:0!important;
			
		}
	}
	.BilDaitalRow{
		padding: 12px 16px;
			background-color: #F6F6F6;
			border-radius: 8px;
			display: flex;
			gap: 10px;
			justify-content: space-between;
			margin-top: 10px;
		&>div{
			
			font-family: "Roboto", sans-serif;
		font-weight: 600;
		font-size: 13px;
	
     color: #000000;
	
		}
	}
	
	
	&>.row{

	}
	.table {
		width: 100%;
		border-collapse: collapse;
		box-shadow: 0px 2px 3px #0029700d;
		font-size: 12px;
		border-radius: 8px;
		tbody {
			tr {
				border-bottom: 1px solid #00297024;
				.viewButton{
					background-color: transparent;
					border: none;
					padding: 10px;
					color: #fa514c;
					cursor: pointer;
					transition: all .3s;
					&:hover{
						color: #da423d;
					}
				}

				&:last-child {
					border-bottom: none;
				
				}
				&:nth-child(even) {
					td{
						background-color: #F6F6F6;
					}
				
				}
			}
		}
		th,
		td {
			padding: 8px 12px;
			text-align: left;
			text-transform: capitalize;
			&.text-right {
				text-align: right;
			}
			&.text-center {
				text-align: center;
			}
			&.text-left {
				text-align: left;
			}
		}
		th {
			background-color: #401600;
			color: #fff;
			border: none;
			white-space: nowrap;
			font-weight: 500;
			padding: 8px 12px;
			&.remark {
				padding: 0 60px;
				white-space: nowrap;
			}
			
		}
		td {
			white-space: nowrap;
		       padding-block:4px;
			   span{white-space: nowrap;}
			.select-link > div {
				display: inline-block;

				color: #00baf2;
				border: 2px solid #00baf2;
				padding: 5px 15px;
				text-align: center;
				min-width: 100px;
				border-radius: 2px;
				white-space: nowrap;
				cursor: pointer;
			}
			&.remark {
				white-space: normal;
				text-align: center;
			}
			&.fail {
				color: red;
			}
			&.success {
				color: green;
			}
			.image-with-text {
				display: flex;
				align-items: center;
				img {
					margin-right: 10px;
				}
			}
		}
		.card-th{
			display: none;
		}
	}
	@media only screen and (max-width: 767px){
		&.table-card-wrapper{
			border: none;
		
	
			.table{
				
				thead{
					display: none;
				}
				tbody{
                    tr{
						margin-bottom: 20px;
							border-bottom: none;
							background-color: #f9f9f9;
							border-radius: 10px;
							padding: 20px 10px;
							box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
						display: block;
						td{
							padding: 4px 10px;
							display: flex;
							justify-content: space-between;
							&>span.card-th{
                               display: block;
							   font-weight: 600;
							}
							.d-m-none{
								display: none;
							}
							&>span:last-child{
								font-weight: normal;
							}
							&.td-none{
								display: none;
							}
						}
					}
				}

			}
		}



	}
	@media only screen and (max-width: 480px){
		&.table-card-wrapper{
			.table{
				
				font-size: 10px!important;

			}
		}



	}

	
`;

export const TabContentWrapper = styled.div`
	position: relative;
	
	flex: 1 1 100%;
	display: flex;
	background: #FFFFFF;
box-shadow: 0px 3px 50px #00000014;
border-radius: 20px;
overflow: hidden;


	.msg {
		text-align: center;
		border-radius: 2px;
	}

	.msg.err {
		color: red;
		background-color: rgb(255 55 55 / 20%);
	}
	.tab-content-left {
		background-color: var(--tab-content-left-bgColor);
		padding: 22px 40px;
		position: relative;
	
		flex: 0 0 333px;
	   min-height: 506px;
	   overflow: visible;
	
	 
		
		.scanner{
			.scanner1{
				margin-bottom: 12px;
				align-items: center;
				padding: 12px;
               border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 4px;
				cursor: pointer;
				input{
					opacity: 0;
				}
				&.active{
					border-color: #D44100;
					   background-color: #D44100;
					   .device{
						p{
							color: #fff;
						}
					   }
				}
				.device{
					align-items: center;
					gap: 20px;
					img{
						height: 40px;
						
					}
					p{
						color: #D44100;
					}
				}
			}
		}
	}
	.tab-content-right {
		min-height: 375px;
		flex: 1;
		background-color: var(--tab-content-left-bgColor);
		max-width: calc(100% - 333px);
		padding: 22px 30px 22px 0;
		margin: 0 0;
		&.addMoneyOr{
			background-color: #F4F8FB;
		
		}
	}
	.headingText{
		margin-bottom: 20px;
		align-items: center;
		h2 {
			margin-bottom: 0;
			margin-top: 0;
			color: #000;
			font-size: 18px;
			height: auto;
		}
	}
	h2 {
		font-size: 18px;
		font-weight: 600;
		line-height: 30px;
		margin-bottom: 15px;
	}
	.radio-group {
		display: flex;
		align-items: center;
		margin-bottom: 9px;
		white-space: nowrap;
		.radio {
			display: flex;
			align-items: center;
			gap: 4px;
		}
	}
	.form-field {
		margin-bottom: 6px;
		input[type='text'],
		.item-select {
			height: 44px;
			font-size: 13px;
			font-weight: 500;
			color: var(--tab-content-input-color);
			width: 100%;
			border: var(--tab-content-input-border);
			border-radius: 4px;
			outline: none;
			padding: 0 8px;
			&::placeholder {
				color: var(--tab-content-input-color);
			}
		}
		.hide {
			display: none;
		}
	}
	.form-field-select-bank {
		margin-top: 10px;
		margin-bottom: 24px;
		position: relative;
		.searchBank {
			margin-bottom: 0;
		}
		.field-label {
			font-size: 15px;
			font-weight: 600;
			line-height: 23px;
			padding-bottom: 10px;
		}
		.radio-group {
			display: flex;
			flex-wrap: wrap;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			margin-bottom: 10px;
		}
		.radio {
			width: 254px;
			padding: 6px 10px;
			position: relative;
			overflow: hidden;
			&.active {
				background-color: #00baf2;
				color: #fff;
			}
			&:hover {
				background-color: #00baf2;
				color: #fff;
			}

			input {
				position: absolute;
				left: -9999px;
			}
		}
		.radio .icon-with-name {
			display: block;
			white-space: break-spaces;
		}
	}

	.bankList {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 258px;
		background-color: #fff;
		top: 100%;
		white-space: break-spaces;
		position: absolute;
		top: 100%;
		box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
		.radio {
			width: 100%;
			padding: 4px 8px;
			position: relative;
			overflow: hidden;
			input {
				position: absolute;
				left: -999999px;
			}

			&:hover {
				background-color: #00baf2;
				color: #fff;
			}
			&.active {
				background-color: #00baf2;
				color: #fff;
			}
		}
	}

	.browse-plans {
		font-weight: 500;
		font-size: 12px;
		margin: 18px 0 24px;
		text-decoration: underline;
		color: #00baf2;
		font-weight: bold;
		cursor: pointer;
	}
	.button-outline {
		width: 100%;
		&.loading {
			cursor: not-allowed;
			.spinner {
				display: inline-block;
				width: 14px;
				height: 14px;
				border: 2px solid #00baf2;
				border-top: 2px solid transparent;
				border-radius: 50%;
				margin-right: 4px;
				animation: spin 2s infinite linear;
			}

			@keyframes spin {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
	}
	.mt20 {
		margin-top: 20px;
	}
	@media only screen and (max-width: 767px) {
		background-color: #fff;
		z-index: 111;
  padding-bottom: 30px;
		.tab-content-right {
			position: absolute;
			max-width: 100%;
			width: 100%;
			padding: 20px 10px;
			box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
			visibility: hidden;
			opacity: 0;
			padding-top: 38px;
			transition: visibility 0.3s, opacity 0.3s;
			z-index: 1000;

			&.active {
				visibility: visible;
				opacity: 1;
				z-index: 1;
				position: fixed;
			bottom: 0;
			left: 0;
			top: 80px;
			width: 100%;
			border-radius: 20px 20px 0 0;
			&::after{
				content: "";
				display: block;
				position: absolute;
				background-color: rgba(0, 0, 0, 0.02);
				/* backdrop-filter: blur(2px); */
				bottom: calc(100% - 20px);
				height: 100px;
				left: 0;
				right: 0;
				z-index: -1;
			}
			}
		}

		.tab-content-left {
			flex: 0 1 auto;
			width: 100%;
			max-width: 100%;
			margin: auto;
			min-height: 100vh;
			
			z-index: -1;
		
		}
		.close-pop {
			width: 28px;
			height: 28px;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			right: 4px;
			top: 4px;
			z-index: 1;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		}
	}
`;

export const PlanWrapper = styled.div`
	background-color: #fff;
	position: relative;
	flex: 1;
	.plan-tabs-wrapper {
		display: flex;
		overflow-x: auto;
		.plan-tab {
			padding: 0 20px 6px;
			border-bottom: 2px solid transparent;
			color: #888;
			text-transform: capitalize;
			text-align: center;
			font-weight: 600;
			cursor: pointer;
			&.active {
				border-bottom: 2px solid #E31E1E;
			}
		}
	}
	.plan-details-wrapper {
	
		overflow-y: auto;
		padding-right: 20px;
		&.plan-details-wrapper-mobile {
			/* max-height: 310px; */
		}
		.details {
			display: flex;
			padding-bottom: 10px;
			border-bottom: 1px dashed #e5e5e5;
			margin-bottom: 30px;
			> div {
				flex: 0 0 25%;
				strong {
					font-size: 18px;
					font-weight: 500;
					display: inline-block;
					vertical-align: top;
				}
				span {
					color: #888;
					display: block;
				}
				&.select-link {
					text-align: right;
					strong {
						color: #00baf2;
						border: 2px solid #00baf2;
						padding: 5px 15px;
						text-align: center;
						min-width: 100px;
						border-radius: 2px;
						white-space: nowrap;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 767px){
		.plan-tabs-wrapper{
			
            .plan-tab{
				padding: 10px 10px;
			}
		}
	}
`;

export const YellowStripWrapper = styled.div`
	&.yellow-strip {
		background-color: #ffdd00;
		width: 100%;
		text-align: center;
		font-size: 15px;
		line-height: 23px;
		padding: 7px 10px;
		box-shadow: 0 1px 1px #dfdfdf;
	}
`;

export const TrippleDotWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	.tripple-dot {
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: #777;
		position: relative;
		&::before,
		&::after {
			content: '';
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: #777;
			position: absolute;
		}
		&::before {
			top: 9px;
		}
		&::after {
			bottom: 9px;
		}
	}
`;

export const ModalWraper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	bottom: 0;
	background-color: #00000059;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: baseline;
	justify-content: center;
	overflow: auto;
	z-index: 1;

	
	.modal,
	.modal-success {
		border-radius: 2px;



		width: ${(props) => (props.width ? props.width : '600px')};
		position: relative;
		margin: 50px 0;
		padding: 35px 27px !important;
		transition: all .3s;
		background: #FFFFFF ;
box-shadow: 0px 3px 50px #00000014;
border-radius: 20px;
		&.zoom-0{
		transform: scale(0.0);
		
	}
	&.zoom-1{
		transform: scale(1.0);
	}
		.modal-header {
			display: flex;
			.logo {
				p {
					font-size: 10px;
					font-family: sans-serif;
					color: #000000;
					line-height: 15px;
				}
			}
			.buttons button {
				height: 32px;
				padding: 0 10px;
				border-radius: 25px;
			}
		}
		.modal-title {
			font-size: 25px;
			color: #080f11;
			margin-bottom: 12px;
			h2 {
				margin-bottom: 8px;
			}
			.amount {
				font-size: 24px;
				color: #D44100;
				margin-bottom: 8px;
			}
			.amount-text {
				font-size: 13px;
			}
		}
		.modal-body {
			.txn-daital-h {
				font: normal 600 14px Roboto;
				color: #012d5a;
				margin-bottom: 12px;
				position: relative;
			}
			.txn-desc {
				font-size: 13px;
				font-weight: 500;
				color: #000000;
				background: #F6F6F6 0% 0% no-repeat padding-box;
                 border-radius: 8px;
				 padding: 4px 9px;
				 margin-bottom: 10px;

				.txn-desc-right {
				     
					& > div {
						margin-bottom: 6px;
						display: flex;
						gap: 8px;
						
					}
					.heading {
						display: inline-block;
						width: 110px;
					}
					span{
						display: inline-block;
						
					}
				}
			}
				.txn-desc-left {
					//min-width: 220px;
					& > div {
						margin-bottom: 8px;
						display: flex;
						gap: 8px;
					}
					.date {
						text-align: end;
						font-size: 10px;
					}
					span{
					  display: block;
					}
				}
			
		}
		.modal-close {
			position: absolute;
			 top: 6px;
			right: 6px; 
			
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #fff;
		
			padding: 4px;
			z-index: 9;
			box-shadow: 0 0 5px #aaa;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			svg{
				transition: all .3s;
			}
		
			&:hover{
				/* svg{
					fill: red;
					transform: rotate( 180deg);
				} */
			}
		}
	}
	.modal-body-aeps {
		.success {
			color: green;
			font-size: 13px;
			img {
				margin-right: 4px;
				margin-top: 4px;
			}
		}
	}
	.MiniStatementList {
		.txn-daital-h {
			margin-bottom: 4px !important;
		}
		.MiniStatement {
			border: 1px solid #e8e8e8;
			border-radius: 4px;
			height: 150px;
			overflow-y: auto;
			table {
				width: 100%;

				thead tr {
					background-color: #f7f8f9;
					color: #012d5a;
					th {
						padding: 8px;
						text-align: start;
						&:last-child {
							text-align: end;
						}
					}
				}
				tbody {
					tr {
						td {
							padding: 4px 8px;
							&.red {
								color: red;
							}
							&.green {
								color: green;
							}

							&:last-child {
								text-align: end;
							}
						}
					}
				}
			}
		}
	}
	.modal {
		.modal-footer {
			margin-top: 20px;
		}
	}
	.buttons button {
		border-radius: 25px;
	}
	@media print {
		background-color: #fff;
		overflow-x: hidden;
		visibility: visible;
		.modal-close {
			display: none;
		}

		.MiniStatement {
			height: auto;
		}
		.buttons button {
			display: none;
		}
	}
`;

export const LoaderWrapper = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #00000056;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 111;

	 .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FF3D00 #FF3D00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
} 
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    
    
`;

export const AlertWrapper = styled.div`
	padding: 10px 25px 10px 10px;
	position: relative;
	margin-bottom: 10px;
	border: 1px solid transparent;
	border-radius: 5px;

	&.error {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1;
	}

	&.success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6;
	}
	&.globle {
		position: fixed;
		//width: 50%;
		max-width: 70%;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		z-index: 11111;
		font-size: 14px;
		animation: mymove 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

		.close {
			font-size: 18px;
			position: absolute;
			top: 50%;
			right: 6px;
			transform: translateY(-50%);
		}
	}
	&.error.globle {
		background-color: #fee;
		border: 1px solid #ffb8b8;
		color: #a66;
	}
	&.success.globle {
		background-color: #efe;
		border: 1px solid #ded;
		color: #9a9;
	}
	.close {
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%);
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 #fff;
		filter: alpha(opacity=20);
		opacity: 0.6;
		&:hover {
			color: #000;
			text-decoration: none;
			cursor: pointer;
			filter: alpha(opacity=50);
			opacity: 0.5;
		}
	}
	@keyframes mymove {
		from {
			top: 0px;
		}
		to {
			top: 50px;
		}
	}
	@media only screen and (max-width: 767px) {
		&.globle {
			width: calc(100% - 50px);
		}
	}
`;

export const FilterWrapper = styled.div`
	//height: 74px;
	width: 260px;
	background: #fff;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border: 1px solid #00297024;
	border-radius: 10px;
	opacity: 0;
	visibility: hidden;
	display: flex;
	align-items: center;
	padding: 24px 24px;
	font-family: 'Noto Serif', serif;
	position: absolute;
	top: calc(100% + 10px);
	right: 0px;
	z-index: 1;
	transition: all .3s;
	&.active{
		opacity: 1;
		visibility: visible;
	}
	.filterBox {
		display: flex;
		gap: 10px;
		align-items: center;
		width: 100%;
       flex-wrap: wrap;
		.filter-text {
			font-size: 15px;
			color: #fff;
			font-weight: 600;
		}
		.input-field {
			flex: 1 1 142px;
			max-width: 100%;
			input,
			select {
				padding: 9px 10px;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #F6F6F6;
				font-size: 12px;
				width: 100%;
				border: 2px solid transparent;
				&:focus {
					box-shadow: 0px 1px 2px #0000000F;
                 border: 1px solid #000000;
					outline: none;
				}
			}
			&.date-filter {
				flex: 1 1 240px;
				max-width: 250px;
				padding: 0;
				box-shadow: 0px 1px 2px #00297026;
				border-radius: 5px;
				background-color: #fff;
				font-size: 12px;
				display: flex;
				align-items: center;
				position: relative;
				img {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					&.img-1 {
						left: 8px;
					}
					&.img-2 {
						left: 125px;
					}
					&.img-3 {
						left: 240px;
					}
				}
				& > select {
					background-color: #edfafe;
					/* padding-left: 22px; */
					max-width: 120px;
					box-shadow: none;
					border-width: 0;
					&:focus-visible,
					&:focus {
						border-width: 0;
						outline: none;
					}
				}

				& > input {
					/* padding-left: 22px; */
					max-width: 130px;
					border-width: 0;
					box-shadow: none;
					&:focus-visible,
					&:focus {
						border-width: 0;
					}
				}
			}
			.filter-Button {
				gap: 10px;
				
				max-width: 100%;
			}
			.filterButton {
			border-radius: 8px;
				.btn-loader {
					animation: spin 2s linear infinite;
				}
				@keyframes spin {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
			&.csv {
				margin-left: auto;
			}
		}
	}
	@media only screen and (max-width: 767px){
		.filterBox{
			.filter-text {
				width: 100%;

			}
		}
	}
	@media only screen and (max-width: 500px){
		.filterBox{
			.input-field  {
				flex: 1 1 100%;
				max-width: 100%;
				&.date-filter
				{
					max-width: 500px;
				}
			}
		}
	}
`;
