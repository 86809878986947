import React from "react";
import { LoaderWrapper } from "../styledConstants";
import logo  from '../../../assets/images/favicon.jpeg'


export default function Loader(){

    return(
        <LoaderWrapper>
          
              <span class="loader"></span>
        </LoaderWrapper>
    )
}