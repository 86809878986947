// ParentComponent.js

import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

const TravelAutoLogin = ({ logOut = () => {} }) => {
	useEffect(() => {
		const handleMessage = (event) => {
			try {
				if (event.origin !== 'https://travel.TechFora.com') return;

				if (event.data?.logout) {
					logOut('logout');
				}
			} catch (error) {}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	const token = localStorage.getItem('ID Token');

	useEffect(() => {
		if ((typeof token === 'string' && token === 'null') || isEmpty(token)) return;
    const iframe = document.getElementById('myIframe');
		const handleIframeLoad = () => {
      iframe.contentWindow.postMessage({ token: token }, 'https://travel.TechFora.com');
		};

		iframe.addEventListener('load', handleIframeLoad);

		return () => {
			iframe.removeEventListener('load', handleIframeLoad);
		};
	}, []);

	useEffect(() =>{
		if ((typeof token === 'string' && token === 'null') || isEmpty(token)) return;
		const iframe = document.getElementById('myIframe');
	
		const handleIframeLoad = () => {
      iframe.contentWindow.postMessage({ token: token }, 'https://travel.TechFora.com');
		};
		handleIframeLoad();
	},[token]);

	return (
		<div style={{ display: 'none' }}>
			<iframe id="myIframe" src="https://travel.TechFora.com" title="My Iframe" style={{ width: '100%' }} />
		</div>
	);
};

export default TravelAutoLogin;
