
import React from 'react';
import "./Footer.css"
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-2.svg';
import playstor from '../../assets/images/playstor.svg';
import applestor from '../../assets/images/apple.svg';

function Component() {

  const currentDate = new Date();
const currentYear = currentDate.getFullYear();
  return (
    <footer>
      <div className="footer container flex justify-between  gap-20">
        {/* <div className="logo">
          <Link to='/'>
            <img src={logo} alt="logo" />
          </Link>
          <p className='fs-sm-12'>A simple, secure and convenient way to make all your utility bill payments</p>

        </div> */}
        <div className="menus flex grow-1 justify-between gap-50">
          <div className="compny">
            <h4>Our company</h4>  
            <div>
              <Link to="/dashboard">Home</Link>
              <Link to="/business">Business</Link>
              <Link to="/consumer">Consumer</Link>
              <Link to="/company">Company</Link>
            </div>
          </div>
          {/* <div className="Payments">
            <h4>Services</h4>
              
           <div>
           <Link to="/services"></Link>
           <Link to="/services?type=mobile"> Mobile Recharge</Link>
           <Link to="/services?type=dthbill">DTH Payment</Link>
           <Link to="/services?type=electricity"> Electricity</Link>
           <Link to="/services?type=gasbill"> Gas Bill Payment</Link>
           <Link to="/services?type=emibillpayment"> EMI Payment</Link>
            
           </div>
          </div> */}
          <div className="Support">
            <h4>Support & Services</h4>
            <div>
            {/* <Link to="/our-services">About Services</Link> */}
              <Link to='/faq'> FAQ </Link>             
              <Link to='/contactus'> Contact Us</Link>
              <Link to='/packages'>Plan</Link>
              <Link to='/refundpolicy'>Refund Policy</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-conditions">Terms & conditions</Link> 

            </div>
          </div>
          <div className="Connect">
            <h4>Registered Address</h4>
            <p class="fs-sm-12">Web E Techies Ganga Bhawan, 1193/21, market, <br />opp. Sec 21, Sector 21, Gurugram, <br />Haryana 122016</p>
            <p style={{marginTop:"10px"}}></p>
            <div className="downloadMeritPay flex">
            {/* <a href=' https://play.google.com/store/apps/details?id=com.webetechies.TechFora' target='_blank'> <img src={playstor} alt="playstor" /></a> */}
             </div>
          </div>

        </div>

      </div>
      <div className=" container footerBottom flex justify-between">
        <div className='footer-botton'>
        Copyright © {currentYear} Webetechies private limited All rights reserved.
        </div>
        <div className="socal-icons flex">

<div className='center'><a href="https://www.facebook.com/people/Webetechies/61554939685547/" target='-blank'><i className="fa-brands fa-facebook"></i></a></div>
<div className='center'><a href="https://www.linkedin.com/company/web-e-techies/mycompany/verification/" target='_blank'><i className="fa-brands fa-linkedin"></i></a></div>
<div className='center'> <a href="https://twitter.com/PvtWebetechies" target='_blank' ><i className="fa-brands fa-x-twitter"></i></a></div>
<div className='center'><a href="https://www.instagram.com/web_e_techies/" target='_blank'><i className="fa-brands fa-instagram"></i></a></div>
 
</div>
        {/* <div className='footer-botton'>
        <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-conditions">Terms & conditions</Link> 
        </div> */}

      </div>
    </footer>
  );
}

export default Component;
