import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { isEmpty, sendNotification } from '../../utils/common';
import NotificationBox from './Notification';
import { GlobleContaxt } from '../../Context/GlobleContaxt';


export default function NotificationWrapper() {
    const childRef = useRef(null);
    const {setAlert,alert} = useContext(GlobleContaxt);
	useEffect(() => {
       if(!isEmpty(alert)){
        const time_ = alert.timer || 5000;
        sendNotification(childRef,alert.type, alert.msg,time_ );
       }
     
	}, [alert]);

   

	return ReactDOM.createPortal(
        <NotificationBox ref={childRef} />,
				document.getElementById('alert')
		  )
		
}
