import React from 'react';
import { InputWrapper, CheckboxWrapper } from './style';

export default function input({ elemConfig, name, placeholder, type, onChange }) {
	
	switch (type) {
		case 'checkbox':
			return (
				<CheckboxWrapper className="checkbox-wrapper">
					<label className="flex flex-center">
						<input className="input-checkbox" type="checkbox" name={name} {...elemConfig} onChange={onChange} />
						<span className="text">{placeholder}</span>
					</label>
				</CheckboxWrapper>
			);
		default:
			return (
				<InputWrapper className="input-container">
					<input className="input-text" {...elemConfig} onChange={onChange} />
					<label className="placeholder-text">
						<span className="text">{placeholder}</span>
					</label>
				</InputWrapper>
			);
	}
}
