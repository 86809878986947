import React from 'react';

function IconClose() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
  <g id="Group_1016" data-name="Group 1016" transform="translate(-570.635 -1055.003) rotate(45)">
    <line id="Line_107" data-name="Line 107" x2="20" transform="translate(1151.5 342.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
    <line id="Line_108" data-name="Line 108" x2="20" transform="translate(1161.5 332.5) rotate(90)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
  </g>
</svg>

	);
}

export default IconClose;
